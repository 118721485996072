

export const exampleCvData = [
    {
        "sessionName": "personalInformation",
        "headerName": "Personal Information",
        "firstName": "Yan Pei",
        "lastName": "Li",
        "nickName": "Hiko",
        "email": "liyanpei2004@outlook.com",
        "phoneNumber": "+852 6204 0827",
        "personalWebsite": "hiko.dev",
        "address": "Sha Tin, New Territories, Hong Kong",
        // "introduction": "I am someone who has a passion for exploration, creation, and contributing to society through innovative endeavors. I am strongly interested in Computer Science and consider myself a junior self-taught full-stack software engineer.Currently, I am focusing on learning TypeScript, React using Next.js, the NestJS framework, and Python FastAPI."
        "introduction": "Passionate Self-Taught Full-Stack Software Engineer | Exploring Innovative Solutions in Computer Science | Typescript | React | Next.js | NestJS | Python FastAPI"
    },
    {
        "sessionName": "education",
        "headerName": "EDUCATION EXPERIENCE",
        "educationExperience": [
            {
                "schoolName": "UOW College HongKong",
                "schoolLocation": "Hong Kong",
                "degree": "Associate of Engineering",
                "major": "Some Major",
                "startDate": "2017-01-01",
                "endDate": "2024-08-31",
                "gpa": "3.0"
            }
        ]
    },
    {
        "sessionName": "skill",
        "headerName": "PROGRAMMING LANGUAGES & TECHNICAL SKILLS",
        "languages": [
            {
                "language": "Python",
                "level": "Experienced in Data Cleaning, data scraping and backend development"
            },
            {
                "language": "C++",
                "level": "School student level"
            },
            {
                "language": "Java",
                "level": "School student level"
            },
            {
                "language": "Typescript",
                "level": "Experienced in few projects (Frontend + Backend)"
            }
            ,
            {
                "language": "React",
                "level": "Experienced in few projects (Frontend + Backend)"
            },
            {
                "language": "Git",
                "level": "Using everyday for everything"
            },
            {
                "language": "NoSQL",
                "level": "Experienced in data injection to MongoDB with Python"
            },
            {
                "language": "HTML/CSS",
                "level": "Experienced in developing styled components in React"
            }
        ],
        "technical": [
            {
                "name": "networking",
                "description": [
                    "Basic Authentication (RFC 7617)",
                    "Bearer Authentication (RFC 6750) based on JWT (RFC 7519)",
                    "Encapsulate of Request Header",
                    "OpenAPI and Swagger"
                ]
            },
            {
                "name": "Infrastructure & OS",
                "description": [
                    "Cloudflare DNS",
                    "Ubuntu Server and Desktop",
                    "NGINX Server setup (docker) for the website development",
                    "Docker (Docker Network/control docker status by writing own shell script)",
                    "SSH server setup and management"
                ]
            }
        ]
    },
    {
        "sessionName": "project",
        "headerName": "PROJECTS EXPERIENCE",
        "projectExperience": [
            {
                "title": "Project EASILY-CV: CV Generator (easily-cv.hiko.dev)",
                "startDate": "2023-08",
                "endDate": "2023-10",
                "projectLocation": "Hong Kong",
                "description": "Build an online CV generator as an open source project. It used MongoDB Atlas as data storage and NextJS as Full Stack Web Framework and deployed with docker on Linode for hosting.",
                "features": [
                    {
                        "description": "Deep understanding the state management in React for real-time data rendering",
                        "furtherExplanation": [
                            "User can see real-time update of their CV based on their input"
                        ]
                    }, {
                        "description": "Better understanding of interface and type in Typescript",
                        "furtherExplanation": [
                        ]
                    }, {
                        "description": "Learnt CSS print and playwright for pdf exporting",
                        "furtherExplanation": [
                        ]
                    }
                ]

            },
            {
                "title": "Project ASA: Cloud-based robot delivery management system (asa.hiko.dev)",
                "startDate": "2023-07",
                "endDate": "2023-08",
                "projectLocation": "Hong Kong",
                "description": "It is a client project (web app based on Typescript) that allows users to design and manage routes for robots/humans to deliver files or goods to destinations.",
                "features": [
                    {
                        "description": "Learned how to update objects in react state (list operation and restructure)",
                        "furtherExplanation": [
                            "Add/Remove items in a list (pop, push, top)",
                            "Reset / Discard user changes",
                            "Update value in a deep nested array of objects by previous states and user’s input"
                        ]
                    }, {
                        "description": "Reorder items in a list and slice list to different sub-list by using index",
                        "furtherExplanation": [
                        ]
                    }, {
                        "description": "Learnt knowledge of JWT authentication, server-side rendering, docker containerization and NGINX networking",
                        "furtherExplanation": [
                        ]
                    }
                    , {
                        "description": "Understood and used JWT for user authentication",
                        "furtherExplanation": [
                        ]
                    }
                ]

            },
            {
                "title": "Project RAKU: AI assistant job search system (raku.hiko.dev)",
                "startDate": "2023-03",
                "endDate": "2023-11",
                "projectLocation": "Hong Kong",
                "description": "A self-developed job query chat box that integrates OpenAI's API which allows users to use natural language for querying.",
                "features": [
                    {
                        "description": "Developed website scraping capabilities to scrape and extract data from various famous online employment companies in Hong Kong",
                        "furtherExplanation": [
                        ]
                    }, {
                        "description": "Used Python (FastAPI) as the backend programming language for the project framework",
                        "furtherExplanation": [
                        ]
                    }, {
                        "description": "Leveraged the experience from Project Gaia to design a solid data structure and used MongoDB for building the database",
                        "furtherExplanation": [
                        ]
                    }

                ]

            },
            {
                "title": "Project GAIA: Design data structure",
                "startDate": "2022-11",
                "endDate": "2023-11",
                "projectLocation": "Hong Kong",
                "description": "This project aims to design NoSQL data structure to store geostatic data.",
                "features": [
                    {
                        "description": "Designed database using MongoDB to store non-structural geostatic data for efficient data storage and retrieval",
                        "furtherExplanation": [
                        ]
                    }, {
                        "description": "Learnt the use of hashing for data content comparison and history management",
                        "furtherExplanation": [
                        ]
                    }, {
                        "description": "Learnt how to reformat and restructure raw data (dictionary/JSON) by using python",
                        "furtherExplanation": [
                        ]
                    }

                ]

            }
        ]
    },
    {
        "sessionName": "extraSkill",
        "headerName": "LANGUAGES AND SKILLS",
        "points": [
            "Native Speaker for Mandarin",
            "Able to listen to and speak basic Cantonese",
            "Able to listen to and speak fluent English"
        ]
    }

]